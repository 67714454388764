/* App.css */
.App {
  font-family: "HseSans";
}

header {
  background-color: #ccc;
  color: #fff;
  padding: 10px 0;
  text-align: center;
}

nav {
  background-color: #ccc;

  text-align: center;
  list-style: none; /* Remove bullets from the menu */
}

nav a {
  color: #333;
  text-decoration: none;
  padding: 10px 20px;
  display: inline-block; /* Ensure each link takes full width */
  text-align: center; /* Center the text */
}

nav a.activeLink {
  background-color: #666;
}
.sound-wave {
  width: 100%;
  margin-bottom: 10px !important;
}

::-webkit-scrollbar {
  width: 0.4em;
  height: 8px;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: #2f57ab;
  border-radius: 4px;
}
